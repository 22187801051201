<template>
    <v-container fluid>
        <div class="main">
            <v-row>
                <v-col cols="12">
                    <div class="d-flex justify-space-between mb-3">
                        <v-btn class="rounded-lg" color="gifty" dark depressed to="/fees">
                            <v-icon left>mdi-arrow-left</v-icon>
                            Retour
                        </v-btn>
                        <v-spacer />

                        
                    </div>
                </v-col>
            </v-row>
            <v-card class="rounded-lg shadow" :loading="isLoading" :disabled="isLoading" elevation="0">
                <v-card-text class="p-3">

                    <v-skeleton-loader v-if="isLoading" type="table" />

                    <div v-else>
                        <div class="d-flex justify-space-between mb-2">
                            <div>

                            </div>
                            <div class="d-flex justify-space-between ">
                                <v-text-field v-model="filter.keyword" rounded filled dense style="width: 300px"
                                    append-icon="mdi-magnify" single-line hide-details clearable placeholder="Rechercher..."
                                    v-on:keyup.enter="fetchData" />

                                <v-btn rounded color="primary" class="ml-1" elevation="0" @click="addType">Ajouter</v-btn>
                            </div>
                        </div>

                        <v-divider />
                        <v-skeleton-loader type="table" v-if="loading" />

                        <v-simple-table elevation="0" v-else>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Nom</th>
                                        <th>Nom Ar</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in types.data" :key="item.id">
                                        <td>
                                            {{ item.id }}
                                        </td>
                                        <td>
                                            {{ item.name }}
                                        </td>
                                        <td>
                                            {{ item.name_ar }}
                                        </td>
                                        <td>

                                            <v-menu transition="slide-x-transition" bottom right>

                                                <v-list dense>

                                                    <v-list-item @click="updateType(item)">
                                                        <v-list-item-icon>
                                                            <v-icon color="black">mdi-pencil</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-title>Modifier</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="deleteType(item)">
                                                        <v-list-item-icon>
                                                            <v-icon color="red">mdi-delete</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-title>Supprimer</v-list-item-title>
                                                    </v-list-item>

                                                </v-list>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn color="black" icon dark v-bind="attrs" v-on="on">
                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>


                                            </v-menu>

                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                    </div>

                </v-card-text>
            </v-card>

            <FormFeeTypeDialog ref="formFeeType" @refresh="fetchData" />
        </div>
    </v-container>
</template>

<script>
import FormFeeTypeDialog from "@/views/fees/types/FormFeeTypeDialog.vue";

import { HTTP } from "@/http-common";


export default {
    components: { FormFeeTypeDialog },
    data() {
        return {
            delete_id: null,
            filter: {
                page: 1,
                size: 10,
                keyword: ""
            },
            loading: true,
            types: []
        }
    },
    methods: {
        getImageLink(file) {
            return process.env.VUE_APP_FILE_URL + file;
        },
        fetchData() {
            //this.$store.dispatch("fetchServiceTypes");
            this.loading = true;
            HTTP.get("v1/fees/types",
                {
                    params: {
                        ...this.filter
                    }
                }
            ).then((res) => {
                this.loading = false;
                this.types = res.data.data;
            }).catch((e) => {
                this.loading = false;
                console.log(e);
            });
        },
        addType() {
            this.$refs.formFeeType.open();
        },
        updateType(item) {
            this.$refs.formFeeType.open(item);
        },
        deleteType(item) {
            this.delete_id = item.id;
            this.$confirm_dialog = true;
        },
        removeType() {
            HTTP.delete("v1/fees/types/" + this.delete_id)
                .then(() => {
                    this.$successMessage = "Ce Type du Service a été supprimé";
                    this.fetchData()
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    },
    computed: {
        isLoading() {
            return this.$store.getters.getLoading;
        }
    },
    watch: {
        $is_confirm: function (el) {
            if (el) {
                this.removeType();
                this.$is_confirm = false;
            }
        },
    },

    mounted() {
        this.fetchData();
    }
}
</script>